import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import '../styles/app.scss';
import SEO from '../components/seo';

const WeRockCare = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    console.log(post.yoast_json_ld[0].wordpress__graph[1].description);

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[1].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />

            <section className="page-section smallestwdt respitesec padding-less-under">
                <div className="container">
                    <div className="openplft">
                        <img
                            src={
                                post.acf.we_rock_care_image.localFile
                                    .childImageSharp.fluid.src
                            }
                            alt="respite"
                        />
                       
                    </div>

                    <div className="openprgt withmarbtm">
                        <h2 className="bluetxt">WHY WE ROCK CARE?</h2>
                        <p
                            className="lastitem"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.we_rock_care_content,
                            }}
                        />
                    </div>
					 <div className="werockcareheal">
					 <h5 class="bluetxt">Book we rock care</h5>
					  <iframe
                            src="https://werockthespectrummidland.wrtsfranchise.com/appointment-werockcare.php"
                            title="appointment"
                            className="healcode_widgets"
                        /> 
						</div>  
                </div>
            </section>

            <section
                className="page-section bg-secondary text-white openphours"
                id="pricingsec"
            >
                <div className="container smallestwdt flexwrap">
                    <div className="twothirdcol flexbox toup">
                        <h2 className="yellowtxt">Pricing</h2>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: post.acf.why_we_rock_care_pricing,
                            }}
                        />
                        <h5 className="yellowtxt addinfo">
                            ADDITIONAL INFORMATION
                        </h5>
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    post.acf
                                        .why_we_rock_care_additional_informations,
                            }}
                        />
                    </div>

                    <div className="onethirdcol flexbox todwn">
                        {post.acf.why_we_rock_care_forms_list.map(
                            (formItem, i) => (
                                <a
                                    href={formItem.pdf_form_file.url.source_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="wrtsbtn yellowbtn fullbtn"
                                    key={i}
                                    dangerouslySetInnerHTML={{
                                        __html: formItem.pdf_form_name,
                                    }}
                                />
                            )
                        )}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default WeRockCare;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            content
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
            acf {
                we_rock_care_title
                we_rock_care_content
                we_rock_care_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
                book_we_rock_care_link
                why_we_rock_care_forms_list {
                    pdf_form_name
                    pdf_form_file {
                        url {
                            source_url
                        }
                    }
                }
                why_we_rock_care_pricing
                why_we_rock_care_additional_informations
            }
        }
    }
`;
